@import "../../../styles/2-tools/tools.spacing-mixins.scss";

.Container {
	width: 100%;
	&___padded {
		@include side-padding;
	}
	&___margined {
		@include vertical-margin;
	}
}

.Container_wrapper {
	margin-left: auto;
	margin-right: auto;

	.Container___small & {
		max-width: var(--width-sm);
	}

	.Container___medium & {
		max-width: var(--width-md);
	}

	.Container___large & {
		max-width: var(--width-lg);
	}

	.Container___xlarge & {
		max-width: var(--width-xl);
	}
}
