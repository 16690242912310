@import "../../../../styles/2-tools/tools.media-query.scss";
@import "../../../../styles/2-tools/tools.typography-mixins.scss";
@import "../../../../styles/2-tools/tools.rem.scss";

.PublicationContent {
	display: flex;
	margin-top: var(--spacing--xl);
	@include mq("md", max) {
		flex-direction: column;
	}
}

.PublicationContent {
	:global(.Picture__override) {
		order: 1;
		align-self: center;
		margin: var(--spacing--lg) 0;
		max-width: pxToRem(225);
		border: 1px solid var(--color-primary);
		@include mq("md") {
			order: 0;
			margin: 0 var(--spacing--xl) 0 0;
			align-self: flex-start;
		}

		@media print {
			display: none;
		}
	}
}

.PublicationContent_wrapper {
	@include mq("md", max) {
		margin-top: var(--spacing--lg);
	}
}

.PublicationContent_teaser {
	@include heading--sm;
	margin-bottom: var(--spacing--md);
}

.PublicationContent_button {
	margin-bottom: var(--spacing--lg);
	text-decoration: none;
	display: block;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.PublicationContent_buttonText {
	margin-left: var(--spacing--2xs);
	.PublicationContent_button:hover &,
	.PublicationContent_button:focus & {
		text-decoration: underline;
	}
}
